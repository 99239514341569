<template>
  <v-app>
    <v-card class="mx-auto" width="95%">
      <v-card-text>
        <div class="text--primary" v-if="user">
          {{ $t("メールアドレス") }}：{{ user.email }}
        </div>
        <p>{{ $t("メールアドレスはログインの際に使用されています。") }}</p>

        <div v-if="user" class="text--primary">
          {{ $t("携帯電話") }}：{{ user.phone }}
        </div>
        <p>
          {{
            $t(
              "携帯電話番号はsms（ショートメッセージ）によるお知らせで使用されています。"
            )
          }}
        </p>

        <div class="text--primary">{{ $t("登録済みクリニック") }}：</div>
        <p>
          {{
            $t(
              "処方箋が登録されているクリニック一覧です。ゴミ箱アイコンを押すと登録を解除できます。"
            )
          }}
        </p>

        <v-list subheader two-line>
          <v-list-item
            v-for="(clinic, index) in clinics"
            :key="clinic.clinic_id || index"
          >
            <v-list-item-avatar>
              <v-icon class="grey lighten-1" dark>
                mdi-hospital-building
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                v-text="clinic.clinic_name"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="'患者様番号' + clinic.patientNumber"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <div v-if="clinic.is_unable == true">
              <v-list-item-action>
                <v-btn
                  icon
                  @click="add_dialogs['add' + clinic.clinic_id] = true"
                >
                  <v-icon color="green">mdi-plus</v-icon>復活
                </v-btn>
              </v-list-item-action>
              <v-dialog
                v-model="add_dialogs['add' + clinic.clinic_id]"
                max-width="400"
              >
                <v-card>
                  <v-card-title>
                    <div>登録クリニックを復活します</div>
                  </v-card-title>
                  <v-card-text>
                    <p>
                      {{ clinic.clinic_name }}
                      クリニックを復活すると、クリニックCOMを利用してのご購入が再開できます。<br />
                      <b>お問い合わせ窓口</b><br />
                      お問い合わせは，下記の窓口までお願いいたします。<br />
                      担当部署：「クリニックcom」お問い合わせ窓口<br />
                      電話番号: <a href="tel:050-3637-3860">050-3637-3860</a
                      ><br />
                      メール ：<a href="mailto:info@eyes.science"
                        >info@eyes.science</a
                      >
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="add_dialogs['add' + clinic.clinic_id] = false"
                      >閉じる</v-btn
                    >
                    <v-btn @click="doAdd(clinic.clinic_id)">確認</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <div v-if="clinic.is_unable != true">
              <v-card-actions>
                <v-btn
                  icon
                  @click="show_notice(clinic.clinic_id)"
                  class="mr-10"
                >
                  <v-icon color="indigo lighten-1">mdi-mail</v-icon>
                  {{ $t("通知設定") }}
                </v-btn>
                <v-btn icon @click="show_remove(clinic.clinic_id)">
                  <v-icon color="red lighten-1">mdi-delete</v-icon>
                  {{ $t("解除") }}
                </v-btn>
              </v-card-actions>
              <v-dialog
                v-model="notice_dialogs['notice' + clinic.clinic_id]"
                max-width="400"
              >
                <v-card>
                  <v-card-title>
                    <div>
                      {{ clinic.clinic_name }}<br />
                      {{ $t("お知らせ通知を設定します") }}
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <p>
                      {{
                        $t(
                          "お知らせ通知を解除すると、処方箋期限切れのお知らせ通知を停止することができます。"
                        )
                      }}<br />
                      <br />
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="
                        notice_dialogs['notice' + clinic.clinic_id] = false
                      "
                      >{{ $t("閉じる") }}</v-btn
                    >
                    <v-btn @click="doUnRevice(clinic.clinic_id)">{{
                      $t("受信停止")
                    }}</v-btn>
                    <v-btn @click="doRevice(clinic.clinic_id)">{{
                      $t("受信開始")
                    }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="remove_dialogs['remove' + clinic.clinic_id]"
                max-width="400"
              >
                <v-card>
                  <v-card-title>
                    <div>
                      {{ clinic.clinic_name }}
                      {{ $t("クリニックを解除します") }}
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <p>
                      解除すると、クリニックCOMを利用してのご購入ができなくなります。<br />
                      登録を復活させたい場合は、こちらの画面から操作いただけます。
                      <br /><br />
                      ※ご購入済みで、未配送の商品は登録を解除してもご購入と配送は継続されます<br />
                      ※ご購入履歴の確認ができなくなりますので、ご購入品の返品や交換のご連絡先は、購入通知メールで確認してください。
                    </p>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="
                        remove_dialogs['remove' + clinic.clinic_id] = false
                      "
                      >閉じる</v-btn
                    >
                    <v-btn @click="doTrush(clinic.clinic_id)">確認</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn class="info" @click="doLogout"> {{ $t("ログアウト") }} </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mx-auto mt-5" width="95%">
      <v-card-actions class="ml-15">
        <v-btn icon @click="show = !show" class="pl-10">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          {{ $t("クリニックCOMの登録解除") }}
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-card-text>
            {{
              $t(
                "登録を解除すると、すべての機能をご利用いただけなくなり、再度ご利用いただく際は、すべての情報を再入力する必要があります。登録解除を行う場合は、「登録を解除する」ボタンをクリックしてください。"
              )
            }}
            <br />
            {{
              $t(
                "※ご購入済みで、未配送の商品は登録を解除してもご購入と配送は継続されます。ご購入履歴の確認ができなくなりますので、ご購入品の返品や交換のご連絡先は、購入通知メールで確認してください。"
              )
            }}
          </v-card-text>
          <v-btn class="error ma-10" @click="doLogout">
            {{ $t("登録を解除する") }}
          </v-btn>
        </div>
      </v-expand-transition>
    </v-card>
  </v-app>
</template>

<script>
import { Firebase } from "../config/firebase";
export default {
  name: "about",
  data: () => ({
    show: false,
    my_dialog: false,
    remove_dialogs: {},
    notice_dialogs: {},
    add_dialogs: {},
    user: null,
    clinics: [],
  }),
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  components: {},
  created: async function () {
    const userRef = await Firebase.firestore()
      .collection("Users")
      .doc(this.$store.getters.user.uid);
    await userRef.get().then((doc) => {
      if (doc.exists) {
        this.user = doc.data();
        this.user.id = doc.id;
        this.clinics = this.user.clinics;
      } else {
        console.log("No user document!");
      }
      this.error = true;
    });
    for (let i = 0; i < this.clinics.length; i++) {
      this.$set(
        this.notice_dialogs,
        "notice" + this.clinics[i].clinic_id,
        false
      );
      this.$set(
        this.remove_dialogs,
        "remove" + this.clinics[i].clinic_id,
        false
      );
      this.$set(this.add_dialogs, "add" + this.clinics[i].clinic_id, false);
    }
  },
  methods: {
    show_notice(clinic_id) {
      this.notice_dialogs["notice" + clinic_id] = true;
    },

    show_remove(clinic_id) {
      this.remove_dialogs["remove" + clinic_id] = true;
    },

    // ログアウト処理
    doLogout: async function () {
      await Firebase.auth()
        .signOut()
        .then(() => {
          // ログイン画面に遷移
          this.$router.push({ path: "/login" });
        })
        .catch(() => {
          alert("ログアウト失敗");
        });
    },
    doRevice: async function (id) {
      for (var i = 0; i < this.user.clinics.length; i++) {
        if (id == this.clinics[i].clinic_id) {
          this.clinics[i].is_unnotice = false;
        }
      }
      this.notice_dialogs["notice" + id] = false;
      this.user.clinics = this.clinics;
      const userRef = await Firebase.firestore()
        .collection("Users")
        .doc(this.$store.getters.user.uid);
      await userRef.get().then((doc) => {
        if (doc.exists) {
          this.user = doc.data();
          this.user.id = doc.id;
        } else {
          console.log("No user document!");
        }
        this.error = true;
      });
    },

    doUnRevice: async function (id) {
      for (var i = 0; i < this.user.clinics.length; i++) {
        if (id == this.clinics[i].clinic_id) {
          this.clinics[i].is_unnotice = true;
        }
      }
      this.notice_dialogs["notice" + id] = false;

      this.user.clinics = this.clinics;
      const userRef = await Firebase.firestore()
        .collection("Users")
        .doc(this.$store.getters.user.uid);
      await userRef.update(this.user);
    },

    doAdd: async function (id) {
      for (var i = 0; i < this.user.clinics.length; i++) {
        if (id == this.clinics[i].clinic_id) {
          this.clinics[i].is_unable = false;
          this.clinics[i].is_unnotice = false;
        }
      }

      this.user.clinics = this.clinics;
      const userRef = await Firebase.firestore()
        .collection("Users")
        .doc(this.$store.getters.user.uid);
      await userRef.update(this.user);
    },
    doTrush: async function (id) {
      for (var i = 0; i < this.user.clinics.length; i++) {
        if (id == this.clinics[i].clinic_id) {
          this.clinics[i].is_unable = true;
          this.clinics[i].is_unnotice = true;
        }
      }
      this.user.clinics = this.clinics;
      this.remove_dialogs["remove" + id] = false;
      const userRef = await Firebase.firestore()
        .collection("Users")
        .doc(this.$store.getters.user.uid);
      await userRef.update(this.user);
    },
  },
};
</script>
