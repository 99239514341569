<template>
  <v-card
    class="mx-auto"
    max-width="400"
    tile
  >
    <v-row >
        <v-col>
            <v-btn class="ma-2" outlined color="indigo" elevation="2" v-on:click="back">
                <v-icon left>mdi-arrow-left</v-icon>
                戻る
            </v-btn>
        </v-col>
    </v-row>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>指示処方箋PDFの印刷方法</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>コンビニエンスストアーでの印刷方法</v-list-item-title>
        <p class="ma-4">利用可能な店舗に設置されているマルチコピー機を利用して、ダウンロードした指示処方箋をプリントアウトすることが可能です。</p>
       
            <ul>
                <li class="ma-4">
                    ファミリーマート、ローソン、ポプラ<br/>
                    <a href ="https://networkprint.ne.jp/sharp_netprint/ja/top.aspx" target="_blank">ネットワークプリント</a>
                    会員登録無料。パソコンやスマホから文書や写真を登録して、コンビニのコピー機でかんたんにプリントできるサービスです。
                </li>

                <li class="ma-4">
                    セブインイレブン<br/>
                    <a href ="https://www.printing.ne.jp/index_p.html" target="_blank">ネットプリント</a>
                    セブン‐イレブンのマルチコピー機スマホやパソコンのPDFや写真をコンビニでプリントきます。
                </li>
            </ul>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>


<script>

export default ({
    methods: {
        back : function(){
            history.back()
        }
    }
})
</script>
